import React from 'react';
import { UserOutlined, VideoCameraOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const SidebarData = [

    {
        key: '1',
        icon: <UserOutlined />,
        label: <Link to="/">Home</Link>,
      },
      {
        key: '2',
        icon: <VideoCameraOutlined />,
        label: <Link to="/page1">Page 1</Link>,
      },
      {
        key: '3',
        icon: <UploadOutlined />,
        label: <Link to="/page2">Page 2</Link>,
      },
];
export default SidebarData;


import React, { useEffect, useState } from 'react';
import { Col, Row,Select } from 'antd';
import axios from 'axios';
import { fetchMarkOptions } from '../api/markApi';

const handleChange = (value) => {
  console.log(`selected ${value}`);
};

function Page1() {

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getOptions = async () => {
      const result = await fetchMarkOptions();
      setOptions(result);
    };

    getOptions();
  }, []);


    return <>  <Row>
    <Col span={12}>
    <Select
            defaultValue="PL"
            style={{ width: 120 }}
            onChange={handleChange}
            options={options}
          />
    
    </Col>
    <Col span={12}>Tutaj będą kolejne selecty i buttony</Col>
  </Row>
  <Row>
    <Col span={24}>Tutaj będzie tabela</Col>
  </Row>
  </>
  }

  export default Page1;
import axios from 'axios';

export const fetchMarkOptions = async () => {
  try {
    const response = await axios.get('http://srv20.mikr.us:20133/api/v1/mark');
    return response.data.result;
  } catch (error) {
    console.error('Error fetching options:', error);
    return [];
  }
};
// CustomHeader.js
import React from 'react';
import { Button, Layout, theme } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const { Header } = Layout;

const CustomHeader = ({ kc,collapsed, onToggle }) => {
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();

  return (
    <Header
      style={{
        padding: 0,
       // background: colorBgContainer,
       background: "dark",

      }}
    >
      <Button
        type="text"
        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        onClick={onToggle}
        style={{
          fontSize: '16px',
          width: 64,
          height: 64,
          color: 'white',
        }}
      />
      <Button type="primary"
      onClick={() => { kc.logout({ redirectUri: 'http://mwawrysz.xyz/' }) }}
      style={{
        border:5,
        padding:5,
        position:"left", left: 20,
       // display: 'flex',
    //justifyContent: 'center',
    //alignItems: 'center',
      }}
      >Logout
      </Button>
    </Header>
  );
};

export default CustomHeader;
